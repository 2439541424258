import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import SectionMargin from '../Layout/Section/SectionMargin'
import Img from "gatsby-image"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./quote.css";

const Quote = () => (
  <StaticQuery
    query={graphql`
      query  {

        anilImage: file(relativePath: { eq: "quotes/anil.jpg" }) {
          childImageSharp {
            fixed(width: 105, height: 105) {
            ...GatsbyImageSharpFixed
            }
          }
        }


        davidImage: file(relativePath: { eq: "quotes/david.jpg" }) {
          childImageSharp {
            fixed(width: 105, height: 105) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        michaelImage: file(relativePath: { eq: "quotes/michael.jpg" }) {
          childImageSharp {
            fixed(width: 105, height: 105) {
            ...GatsbyImageSharpFixed
            }
          }
        }

       simonImage: file(relativePath: { eq: "quotes/simon.jpg" }) {
          childImageSharp {
            fixed(width: 105, height: 105) {
            ...GatsbyImageSharpFixed
            }
          }
        }

       paulImage: file(relativePath: { eq: "quotes/paul.jpg" }) {
          childImageSharp {
            fixed(width: 105, height: 105) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        tanerImage: file(relativePath: { eq: "quotes/taner.jpg" }) {
          childImageSharp {
            fixed(width: 105, height: 105) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        chrisImage: file(relativePath: { eq: "quotes/chris.jpg" }) {
          childImageSharp {
            fixed(width: 105, height: 105) {
            ...GatsbyImageSharpFixed
            }
          }
        }

      }
    `}
    render={data => {

      var settings = {
        accessibility: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1220,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 940,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },

        ]
      };

      const styles = {
        margin: '0px',
        padding: '0px',
        border: '2px solid #3988c4',
        borderRadius: '50%',
      }


      return (

        <SectionMargin>

          <div style={{ width: '95%' }}>

            <Slider {...settings}>

            <div>
                <Slide>
                  <Card>
                    <Img style={styles} fixed={data.anilImage.childImageSharp.fixed} />
                    <QuoteText>
                        I will never sell a car any other way. Using Summon was incredibly 
                        convenient and easy. The team was responsive, worked around my 
                        schedule, and were able to sell my car for a great price.
                      <NameText>
                        - Anil. Audi Q7
                      </NameText>
                    </QuoteText>
                  </Card>
                </Slide>
              </div>

              <div>
                <Slide>
                  <Card>
                    <Img style={styles} fixed={data.chrisImage.childImageSharp.fixed} />
                    <QuoteText>
                      From the outset, the communication from
                      the team at Summon has been first class.
                      The bespoke professional photography
                      truly sets the listing apart from others.
                      I would highly recommend the services of
                      Summon.
                      <NameText>
                        - Chris. Mercedes AMG C63
                      </NameText>
                    </QuoteText>
                  </Card>
                </Slide>
              </div>


              <div>
                <Slide>
                  <Card>
                    <Img style={styles} fixed={data.michaelImage.childImageSharp.fixed} />
                    <QuoteText>
                      Summon made the sale of my car easy. Every step
                       along the way was stress free. Communication 
                       and the quality of the advice were both excellent!
                      <NameText>
                        - Michael. Porsche Cayman
                      </NameText>
                    </QuoteText>
                  </Card>
                </Slide>
              </div>

            <div>
                <Slide>
                  <Card>
                    <Img style={styles} fixed={data.davidImage.childImageSharp.fixed} />
                    <QuoteText>
                      Despite the initial thoughts of its too good
                       to be true, they were able to provide the 
                       full service and achieve above what they 
                       valued the vehicle at.
                      <NameText>
                        - David. Audi Q7
                      </NameText>
                    </QuoteText>
                  </Card>
                </Slide>
              </div>

              <div>
                <Slide>
                  <Card>
                    <Img style={styles} fixed={data.simonImage.childImageSharp.fixed} />
                    <QuoteText>
                      You guys have been outstanding to
                      work with, I still can’t believe you
                      got it away in the middle of this
                      lockdown!  It is a great idea for a
                      business and the execution is faultless.
                      <NameText>
                        - Simon. Audi RS Q3
                      </NameText>
                    </QuoteText>
                  </Card>
                </Slide>
              </div>

              <div>
                <Slide>
                  <Card>
                    <Img style={styles} fixed={data.paulImage.childImageSharp.fixed} />
                    <QuoteText>
                      I was delighted from the first encounter.
                      The process for preparing the car for sale,
                      negotiation, pick up and collection upon
                      settlement, was extremely easy.
                      Being time poor, a highly valued service.
                      <NameText>
                        - Paul. Porsche Macan GTS
                      </NameText>
                    </QuoteText>
                  </Card>
                </Slide>
              </div>

              <div>
                <Slide>
                  <Card>
                    <Img style={styles} fixed={data.tanerImage.childImageSharp.fixed} />
                    <QuoteText>
                      It was an excellent process that exceeded
                      my expectations. I will strongly suggest
                      your services to all of my friends.
                      <NameText>
                        - Taner. BMW M850i
                      </NameText>
                    </QuoteText>
                  </Card>
                </Slide>
              </div>

            </Slider>
          </div>

        </SectionMargin>
      )
    }}
  />
)

export default Quote

const Slide = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 400px;
`;

const Card = styled.div`
  position: relative;
  align-self: center;
  text-align: center;
  padding: 30px;
  width: 200px;
  height: 300px;
  border: 1px solid #cecece;
  border-radius: 4px;
  background: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
`;

const QuoteText = styled.div`
  font-size: 0.9rem;
  line-height: 1.2rem;
  letter-spacing: -0.05rem;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
`
const NameText = styled.p`
  text-align: center;
  margin: 10px 0px 10px 0px;
`



